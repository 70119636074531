var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"align":"center","justify":"center"}},[(_vm.type == 'Test')?[(!_vm.finished && _vm.snooze)?_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-icon',{attrs:{"size":"25","color":"grey"}},[_vm._v("mdi-sleep")])],1):_vm._e(),(_vm.level < _vm.maxLevel)?_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[(_vm.maxLevel <= 2)?_c('v-rating',{attrs:{"value":_vm.level,"length":_vm.maxLevel,"dense":"","readonly":"","size":"20","color":"yellow darken-2","background-color":"grey","empty-icon":"mdi-trophy","full-icon":"mdi-trophy"}}):_c('v-rating',{attrs:{"value":_vm.level,"length":_vm.maxLevel,"dense":"","readonly":"","size":"15","color":"yellow darken-2","background-color":"grey","empty-icon":"mdi-trophy","full-icon":"mdi-trophy"}})],1):_vm._e(),_c('transition',{attrs:{"name":"slide-rotate"}},[(_vm.level >= _vm.maxLevel)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"30","color":_vm.$store.state.ux.colorAccent}},on),[_vm._v("mdi-checkbox-marked-circle-outline")])]}}],null,false,3197093276)},[_vm._v(" Test bestanden ")]):_vm._e()],1)]:_vm._e(),(_vm.type == 'ManualApproval')?[(!_vm.finished)?_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"30","color":"grey"}},on),[_vm._v("mdi-account-cancel")])]}}],null,false,1515110987)},[_vm._v(" Es muss noch auf die Freigabe gewartet werden. ")])],1):_vm._e(),_c('transition',{attrs:{"name":"slide-rotate"}},[(_vm.finished)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"30","color":_vm.$store.state.ux.colorAccent}},on),[_vm._v("mdi-checkbox-marked-circle-outline")])]}}],null,false,3197093276)},[_vm._v(" Freigegeben ")]):_vm._e()],1)]:_vm._e(),(_vm.type == 'Scorm')?[_c('transition',{attrs:{"name":"slide-rotate"}},[(_vm.finished)?_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_vm._v(" "+_vm._s(_vm.result)),_c('v-icon',{staticClass:"ml-1",attrs:{"size":"20","color":"yellow darken-2"}},[_vm._v("mdi-trophy")])],1):_vm._e()],1),(_vm.finished)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-progress-linear',{staticStyle:{"width":"50px","margin":"0"},attrs:{"color":"yellow darken-2","height":"5","value":_vm.result}})],1):_vm._e(),(!_vm.finished && _vm.result == 0)?_c('v-icon',{attrs:{"size":"20","color":"grey"}},[_vm._v("mdi-trophy")]):_vm._e(),(!_vm.finished && _vm.result > 0)?_c('span',[_c('v-icon',{attrs:{"size":"20","color":_vm.$store.state.ux.colorAccent}},[_vm._v("mdi-pause-circle-outline")]),_c('v-icon',{staticClass:"ml-1",attrs:{"size":"20","color":"grey"}},[_vm._v("mdi-trophy")])],1):_vm._e()]:_vm._e(),(_vm.type == 'Signature')?[(!_vm.noTestsOrFinished)?_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"30","color":"grey"}},on),[_vm._v("mdi-lock")])]}}],null,false,2243000362)},[_vm._v(" Bitte absolvieren Sie zuerst den Test bzw. warten Sie auf die Freigabe. ")])],1):_vm._e(),(_vm.noTestsOrFinished && !_vm.isSigned)?_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"30","color":_vm.$store.state.ux.colorError}},on),[_vm._v("mdi-pen")])]}}],null,false,1423367033)},[_vm._v(" Ihre Unterschrift fehlt noch. ")])],1):_vm._e(),_c('transition',{attrs:{"name":"slide-rotate"}},[(_vm.isSigned)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"30","color":_vm.$store.state.ux.colorAccent}},on),[_vm._v("mdi-checkbox-marked-circle-outline")])]}}],null,false,3197093276)},[_vm._v(" Zertifikat erteilt ")]):_vm._e()],1)]:_vm._e(),(_vm.type == 'Survey')?[(!_vm.noTestsOrFinished)?_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"30","color":"grey"}},on),[_vm._v("mdi-lock")])]}}],null,false,2243000362)},[_vm._v(" Bitte absolvieren Sie zuerst den Test bzw. warten Sie auf die Freigabe. ")])],1):_vm._e(),(_vm.noTestsOrFinished && !_vm.finished)?_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"30","color":_vm.$store.state.ux.colorAccent}},on),[_vm._v("mdi-thumbs-up-down")])]}}],null,false,3615237926)},[_vm._v(" Bewerten Sie die Schulung ")])],1):_vm._e(),_c('transition',{attrs:{"name":"slide-rotate"}},[(_vm.finished)?_c('v-icon',{attrs:{"size":"30","color":_vm.$store.state.ux.colorAccent}},[_vm._v("mdi-checkbox-marked-circle-outline")]):_vm._e()],1)]:_vm._e(),(_vm.type == 'Rule' || _vm.type == 'ExplanationVideo' || _vm.type == 'Exercise')?[(_vm.result < 100)?_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_vm._v(" "+_vm._s(_vm.result)),_c('v-icon',{staticClass:"ml-1",staticStyle:{"margin-bottom":"4px"},attrs:{"size":"22","color":_vm.$store.state.ux.colorAccent}},[_vm._v("mdi-dumbbell")])],1):_vm._e(),(_vm.result >= 100)?_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_vm._v(" "+_vm._s(_vm.result)),_c('v-icon',{staticClass:"ml-1",attrs:{"size":"20","color":"success"}},[_vm._v("mdi-checkbox-marked-circle-outline")])],1):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-progress-linear',{staticStyle:{"width":"50px","margin":"0"},attrs:{"color":"yellow darken-2","height":"5","value":_vm.result}})],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }