











































































































































































































































































































import _ from "lodash";
import rest from "@/rest";
import { RestResponse } from "@/interfaces/RestResponse";
import wretch, { WretcherError } from "wretch";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import router from "@/router";
import { PortalUserVM, OrganizationVm } from "@/interfaces/PortalUserVM";
import { ParamGetExercises } from "@/interfaces/ParamGetExercises";
import { ExSubject } from "@/interfaces/ExSubject";
import { ParamBool } from '@/interfaces/ParamBool';
import { ParamDictionary } from "@/interfaces/ParamDictionary";
import { ResultOverviewVM } from "@/interfaces/ResultOverviewVM";
import ExBundleStatus from "../components/ExerciseComponents/ExBundleStatus.vue";
import { ExBundleAssignmentVM } from "@/interfaces/ExBundleAssignmentVM";
import { GroupVm } from "@/interfaces/GroupVm";
import { SubGroupVm } from "@/interfaces/SubGroupVm";
import { ExCategory2 } from "@/interfaces/ExCategory2";
import { ExCategory1 } from "@/interfaces/ExCategory1";
import { ExCategory3 } from "@/interfaces/ExCategory3";
import { Criteria, GroupExtVm } from "@/interfaces/GroupExtVm";
import { CategorySelectListsVm } from "@/interfaces/CategorySelectListsVm";
import { vuex } from "@/store";
import { List } from "linq-collections";
import { ParamMemberSuggestionVm } from "@/interfaces/ParamMemberSuggestionVm";
import moment from "moment";

// import VueQrcode from "@chenfengyuan/vue-qrcode"; // https://github.com/fengyuanchen/vue-qrcode

const globals = namespace("globals");
const auth = namespace("auth");

@Component({
  components: {
    ExBundleStatus
    // 'qrcode': VueQrcode
  }
})
export default class ExercisesView extends Vue {
  @globals.Getter selectedOrg!: OrganizationVm;
  @globals.Getter selectedGroup!: GroupVm;
  @globals.Getter selectedSubGroup!: SubGroupVm;
  @globals.Getter selectedUser!: PortalUserVM | null;
  @globals.Getter selectedCategory1!: ExCategory1 | null;
  @globals.Getter selectedCategory2!: ExCategory2 | null;
  @globals.Getter selectedCategory3!: ExCategory3 | null;
  @auth.Getter isAdmin: any;
  @auth.Getter isGroupAdmin: any;
  @auth.Getter isLearner: any;

  init = true;
  excelLoading = false;
  certificateExcelLoading = false;
  isSearching = false;
  search: string | null = null;
  users: PortalUserVM[] | null = null;
  selectedUserId: string | null = null;
  showUserView = false;
  orgs: OrganizationVm[] = [];
  localSelectedOrg: OrganizationVm | null = null;
  groups: GroupExtVm[] | null = null;
  selectedGroupId: number = 0;
  selectedSubGroupId: number = 0;
  bundleAssignments: ExBundleAssignmentVM[] | null = [];
  paramDir: ParamDictionary = { dictionary: {}};
  category1Items: ExCategory1[] = [];
  category2Items: ExCategory2[] = [];
  category3Items: ExCategory3[] = [];

  resultsOverview: ResultOverviewVM | null = null;
  selectAll: boolean = true;

  breadcrumbItems = [
    { text: 'Start', disabled: false, href: '/home' },
    { text: 'Schulungen', disabled: true, href: '/exercises' },
 ];

  async mounted() {
    // if (this.selectedGroup == null) {
    //   router.push("/");
    //   return;
    // }
    // if (this.selectedGroup)
    //   this.selectedGroupId = this.selectedGroup?.id ?? 0;
    // else
    //   this.selectedGroupId = 0;

    if (this.selectedUser)
      this.selectedUserId = this.selectedUser?.id;
    else
      this.selectedUserId = null;

    await this.updateOrgList();
    await this.getGroups();
    await this.searchUsers();
    this.init = false;

    await this.loadCategories("cat1");
    if (this.selectedGroup)
      this.getExBundles();
  }


  @Watch("search")
  async onSearch(val) {
    if (this.init) return;

    if (this.selectedUserId && this.search) {
      // Avoid loosing the search text if we have no suggestions
      let temp = this.search;
      vuex.globals.setUser(null);
      this.selectedUserId = null;
      await this.$globalHelper.delay(3);
      this.search = temp;
    }

    this.debounce();
  }
  debounce = _.debounce(this.loadSuggestions, 700, { leading: true, trailing: true});

  async loadSuggestions() {
    if (this.isSearching) return;

    this.searchUsers();
  }

  async updateOrgList() {
    // this.orgs = await rest.url("groupAdmin/getGroupAdminOrganizations").get();
    let params = <ParamDictionary>{dictionary: {}};
    params.dictionary!["LoadAllOrgs"] = "false";
    params.dictionary!["AddAllOrgsSelection"] = "true";
    this.orgs = await rest.url("userManagement/loadOrganizations").post(params);

    if (this.orgs.length > 0) {
      if (this.selectedOrg) {
        if (new List(this.orgs).any(s => s.id == this.selectedOrg.id)) {
          let org = new List(this.orgs).single(s => s.id == this.selectedOrg.id);
          this.localSelectedOrg = org;
          return;
        }
      }
      vuex.globals.setOrg(this.orgs[0]);
    } else {
      vuex.globals.setOrg(null);
    }
    this.localSelectedOrg = this.selectedOrg;
  }

  async onOrgChanged() {
    vuex.globals.setOrg(this.localSelectedOrg);
    await this.getGroups();
    await this.searchUsers();
    await this.loadCategories("cat1");
    this.getExBundles();
  }

  async getGroups() {
    if (this.selectedOrg == null) return;
    this.selectedGroupId = 0;
    this.selectedSubGroupId = 0;
    this.groups = await rest
      .query({ orgId: this.selectedOrg.id, asGroupAdmin: false })
      .url("groupAdmin/getMyGroups").get();
    if (this.groups == undefined || this.groups.length == 0) {
      vuex.globals.setGroup(null);
      vuex.globals.setSubGroup(null);
      // console.log("no group selected");
      return;
    }

    // Add "Alle Gruppen" to groups lists
    new List(this.groups).insert(0, <GroupExtVm>{
      id: 0,
      name: "Alle Gruppen",
      isDynamic: false,
      criterias: <Criteria[]>{},
      organizationId: 0,
      orgName: this.selectedOrg?.name ?? "",
      allowModify: false,
      subGroups:[],
      readAccessUserCount: 0,
      readAccessUsers: [],
      searchValue: "",
      lastChangedBy: "",
      lastChangeDate: new Date(),
      userCount: 0,
      users: []
    });

    // Add "Alle Untergruppen" to all subgroup lists
    let groupList = new List(this.groups);
    groupList.forEach(g => {
      if (g.subGroups) {
        new List(g.subGroups).insert(0, <SubGroupVm>{
          id: 0,
          parentGroupId: g.id,
          parentGroupName: "",
          name: "Alle Untergruppen",
          lastChangedBy: "",
          lastChangeDate: new Date(),
          allowModify: false,
          userCount: 0,
          users: []
        });
      }
    })

    // Try to select last selected group
    if (this.groups.length > 0 && this.selectedGroup != null) {
      let lastSelectedGroup = this.groups.find(g => g.id == this.selectedGroup.id) ?? null;
      if (lastSelectedGroup) {
        this.selectedGroupId = lastSelectedGroup.id;
        vuex.globals.setGroup(lastSelectedGroup);
        // console.log("last group selected");
      }
    }

    // Select first group
    if (this.selectedGroupId == 0 && this.groups.length > 0) {
      this.selectedGroupId = this.groups[0].id;
      vuex.globals.setGroup(this.groups[0]);
      // console.log("first group selected");
      // console.log("selectedGroup: ", this.selectedGroup);
    }

    // Try to select last selected sub group
    if (this.selectedGroup?.subGroups && this.selectedSubGroup) {
      let lastSelectedSubGroup = this.selectedGroup.subGroups.find(s => s.id == this.selectedSubGroup.id);
      if (lastSelectedSubGroup) {
        this.selectedSubGroupId = lastSelectedSubGroup.id;
        vuex.globals.setSubGroup(lastSelectedSubGroup);
        // console.log("last sub group selected");
      }
    }

    // Select first sub group
    if (this.selectedSubGroupId == 0) {
      this.selectFirstSubGroup();
    }
  }

  selectFirstSubGroup(): void {
    if (this.selectedGroup?.subGroups && this.selectedGroup.subGroups.length > 0) {
      this.selectedSubGroupId = this.selectedGroup.subGroups[0].id;
      vuex.globals.setSubGroup(this.selectedGroup.subGroups[0]);
      return;
    }
    this.selectedSubGroupId = 0;
    vuex.globals.setSubGroup(null);
  }

  async onGroupChanged() {
    // console.log("onGroupChanged: ", this.selectedGroupId);
    let selectedGroup = this.groups!.find(g => g.id == this.selectedGroupId) ?? null;
    vuex.globals.setGroup(selectedGroup);
    this.selectFirstSubGroup();
    await this.searchUsers();
    await this.loadCategories("cat1");
    this.getExBundles();
  }

  async onSubGroupChanged() {
    // console.log("onSubGroupChanged: ", this.selectedSubGroupId);
    if (!this.selectedGroup?.subGroups) return;

    let selectedSubGroup = this.selectedGroup.subGroups.find(s => s.id == this.selectedSubGroupId);
    if (selectedSubGroup)
      vuex.globals.setSubGroup(selectedSubGroup);

    await this.searchUsers();
    this.getExBundles();
  }

  async cat1Changed(cat1: ExCategory1) {
    // console.log("grade changed, get exercises")
    // this.selectedCat1Item = cat1;
    vuex.globals.setCategory1(cat1);
    await this.loadCategories("cat1");
    this.getExBundles();
  }

  async cat2Changed(cat2: ExCategory2) {
    // console.log("grade changed, get exercises")
    // this.selectedCat2Item = cat2;
    vuex.globals.setCategory2(cat2);
    await this.loadCategories("cat2");
    this.getExBundles();
  }

  async cat3Changed(cat3: ExCategory3) {
    // console.log("grade changed, get exercises")
    // this.selectedCat3Item = cat3;
    vuex.globals.setCategory3(cat3);
    await this.loadCategories("cat3");
    this.getExBundles();
  }

  async loadCategories(changeEvent: string): Promise<void> {
     if (this.paramDir == null || this.paramDir.dictionary == null)
      return;

    this.paramDir.dictionary["ChangeEvent"] = changeEvent;
    this.paramDir.dictionary["UserView"] = "false";
    this.paramDir.dictionary["UserId"] = this.selectedUser?.id ?? "";
    this.paramDir.dictionary["OrgId"] = this.selectedOrg?.id.toString() ?? "0";
    this.paramDir.dictionary["GroupId"] = this.selectedGroup?.id.toString() ?? "0";
    this.paramDir.dictionary["Category1Id"] = this.selectedCategory1?.id.toString() ?? "0";
    this.paramDir.dictionary["Category2Id"] = this.selectedCategory2?.id.toString() ?? "0";
    this.paramDir.dictionary["Category3Id"] = this.selectedCategory3?.id.toString() ?? "0";

    await rest.url("exercises/loadUserCategories").post(this.paramDir)
    .then((result: CategorySelectListsVm) => {
      this.category1Items = result.category1Items!;
      // this.selectedCat1Item = result.selectedCat1Item
      vuex.globals.setCategory1(result.selectedCat1Item);
      this.category2Items = result.category2Items!;
      // this.selectedCat2Item = result.selectedCat2Item
      vuex.globals.setCategory2(result.selectedCat2Item);
      this.category3Items = result.category3Items!;
      // this.selectedCat3Item = result.selectedCat3Item
      vuex.globals.setCategory3(result.selectedCat3Item);
    });
  }

  get selectedCat1ItemName() {
    if (!this.selectedCategory1)
      return "-";
    return this.selectedCategory1.name;
  }

  get selectedCat2ItemName() {
    if (!this.selectedCategory2)
      return "-";
    return this.selectedCategory2.name;
  }

  get selectedCat3ItemName() {
    if (!this.selectedCategory3)
      return "-";
    return this.selectedCategory3.name;
  }

  async searchUsers() {
    if (this.selectedGroupId == 0) {
      this.users = [];
      vuex.globals.setUser(null);
      this.selectedUserId = null;
      return;
    }

    if (this.isSearching)
      return;

    this.isSearching = true;

    let param = <ParamMemberSuggestionVm>{
      groupId: this.selectedGroup?.id ?? 0,
      subGroupId: this.selectedSubGroupId,
      addSelectAll: false,
      search: this.search
    }

    rest
      .url("groupAdmin/loadGroupOrSubGroupMemberSuggestions")
      .post(param)
      .then(result => {
        // console.log(result);
        this.users = result;
        if (this.users!.length > 0 && this.selectedUser != null) {
          let selectedUser = this.users!.find(p => p.id == this.selectedUser?.id);
          if (selectedUser != null) {
            this.selectedUserId = selectedUser.id;
            return;
          }
        }
        if (this.selectedUser != null) {
          this.selectedUserId = null;
          vuex.globals.setUser(null);
        }
      })
    .finally(() => this.isSearching = false)
    .catch(err => {
      console.log(err)
    });
  }

  async getResults() {
    this.resultsOverview = await rest.url("exercises/getResultsOverview").get();
    // console.log(this.resultsOverview);
  }

  async getExBundles() {
    // let pupilId: string = null;
    // if (this.selectedUser != null)
    //   pupilId = this.selectedUser.id;
    this.bundleAssignments = [];

    let param: ParamGetExercises = {
      orgId: this.selectedOrg?.id ?? 0,
      groupId: this.selectedGroupId,
      subGroupId: this.selectedSubGroupId,
      userId: this.selectedUserId,
      category1Id: this.selectedCategory1?.id ?? 0,
      category2Id: this.selectedCategory2?.id ?? 0,
      category3Id:  this.selectedCategory3?.id ?? 0,
      bundleId: 0, // not relevant
      enabled: false,
      writeResults: false,
      showUserView: this.showUserView
    };
    this.bundleAssignments = await rest.url("exercises/getAssignedExBundles").post(param);
  }

  async onExcelExport() {
    this.excelLoading = true;
    let param: ParamGetExercises = {
      orgId: this.selectedOrg?.id ?? 0,
      groupId: this.selectedGroupId,
      subGroupId: this.selectedSubGroupId,
      userId: this.selectedUserId,
      category1Id: this.selectedCategory1?.id ?? 0,
      category2Id: this.selectedCategory2?.id ?? 0,
      category3Id:  this.selectedCategory3?.id ?? 0,
      bundleId: 0, // not relevant
      enabled: false,
      writeResults: false,
      showUserView: this.showUserView
    };
    // this.bundleAssignments = await rest.url("exercises/exportGroupResultsExcel").post(param);
    let token = window.localStorage.getItem("digiClassAuth");
    wretch("/api/exercises/exportGroupResultsExcel")
    .auth(`Bearer ${ token }`)
    .post(param)
    .blob(data => {
      // const blob = new Blob([data], { type: 'application/pdf' });
      const blob = new Blob([data]);
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = "Schulungsergebnisse.xlsx";
      link.click();
      URL.revokeObjectURL(link.href);
    })
    .catch(err => {
      let apiError: RestResponse = JSON.parse(err.text);
      this.$store.commit("ux/SB_FAILURE", {
        message: `Server Error - ${apiError.description}`,
        timeout: 0
      });
    })
    .finally( () => {
      this.excelLoading = false;
    });
  }

  async onCertificateExcelExport() {
    this.certificateExcelLoading = true;
    let param: ParamGetExercises = {
      orgId: this.selectedOrg?.id ?? 0,
      groupId: this.selectedGroupId,
      subGroupId: this.selectedSubGroupId,
      userId: this.selectedUserId,
      category1Id: this.selectedCategory1?.id ?? 0,
      category2Id: this.selectedCategory2?.id ?? 0,
      category3Id:  this.selectedCategory3?.id ?? 0,
      bundleId: 0, // not relevant
      enabled: false,
      writeResults: false,
      showUserView: this.showUserView
    };

    let token = window.localStorage.getItem("digiClassAuth");
    wretch("/api/exercises/exportCertificatesExcel")
    .auth(`Bearer ${ token }`)
    .post(param)
    .blob(data => {
      // const blob = new Blob([data], { type: 'application/pdf' });
      const blob = new Blob([data]);
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = "Zertifikate.xlsx";
      link.click();
      URL.revokeObjectURL(link.href);
    })
    .catch(err => {
      let apiError: RestResponse = JSON.parse(err.text);
      this.$store.commit("ux/SB_FAILURE", {
        message: `Server Error - ${apiError.description}`,
        timeout: 0
      });
    })
    .finally( () => {
      this.certificateExcelLoading = false;
    });
  }

  onUserChanged() {
    if (this.selectedUserId != null) {
      let selectedUser = this.users!.find(p => p.id == this.selectedUserId) ?? null;
      // this.$store.commit("globals/setUser", selectedUser);
      vuex.globals.setUser(selectedUser);
    } else {
      this.showUserView = false;
      // this.$store.commit("globals/setUser", null);
      vuex.globals.setUser(null);
    }
    this.getExBundles();
  }

  // async changeSubject(subject: ExSubject) {
  //   this.$store.commit("globals/setSubject", subject);
  //   if (this.selectedGroup)
  //     this.getExBundles();
  // }

  showExerciseBundle(assignment: ExBundleAssignmentVM) {
    // this.$store.commit('globals/setExBundleAssignment', assignment);
    // if (this.selectedUserId) {
    //   router.push("/exerciseBundle");
    // } else {
    //   router.push("/groupExerciseOverview");
    // }
    if (assignment.userId == null) {
      // Group assignment
      vuex.globals.setGroupAssignment(assignment);
      router.push("/groupExerciseOverview");
    } else {
      // User assignment
      vuex.globals.setExBundleAssignment(assignment);
      router.push("/exerciseBundle");
    }
  }

  dateToLocal(date: string) {
    if (!date)
      return "";

    // let date = moment(finishedDate).toDate().toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
    let dateFormated = moment(date).toDate().toLocaleString([], {year: 'numeric', month:  '2-digit', day: '2-digit'});
    return dateFormated;
  }

  goBack() {
    router.go(-1);
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
